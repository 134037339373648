@mixin object-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  $circleSize: 165px;
  $radius: 100px;
  $shadow: 0 0 10px 0 rgba(255,255,255,.35);
  $fontColor: rgb(250,250,250);
  
  .profile-pic {
    color: transparent;
    transition: all .3s ease;
    @include object-center;
    position: relative;
    transition: all .3s ease;
    width: fit-content;
    input {
      display: none;
    }
    
    img {
      position: absolute;
      object-fit: cover;
      width: $circleSize;
      height: $circleSize;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
      border-radius: 15px;
    
      z-index: 0;
    }
    
    .-label {
      cursor: pointer;
      height: $circleSize;
      width: $circleSize;
    }
    
    &:hover {
      .-label {
        @include object-center;
        background-color: rgba(0,0,0,.8);
        z-index: 10000;
        color: $fontColor;
        transition: background-color .2s ease-in-out;
        border-radius: 15px;
        margin-bottom: 0;
      }
    }
    
    span {
      display: inline-flex;
      padding: .2em;
      height: 2em;
    }
  }




  
// Breakpoints
$bp-maggie: 15em; 
$bp-lisa: 30em;
$bp-bart: 48em;
$bp-marge: 62em;
$bp-homer: 75em;

// Styles




.container {
  margin: 5% 3%;
  
  @media (min-width: $bp-bart) {
    margin: 2%; 
  }
  
  @media (min-width: $bp-homer) {
    margin: 2em auto;
    max-width: $bp-homer;
  }
}

.responsive-table {
  width: 100%;
  margin-bottom: 1.5em;
  border-spacing: 0;
  
  @media (min-width: $bp-bart) {
    font-size: .9em; 
  }
  
  @media (min-width: $bp-marge) {
    font-size: 1em; 
  }
  
  thead {
    // Accessibly hide <thead> on narrow viewports
    position: absolute;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    padding: 0;
    border: 0;
    height: 1px; 
    width: 1px; 
    overflow: hidden;
    
    @media (min-width: $bp-bart) {
      // Unhide <thead> on wide viewports
      position: relative;
      clip: auto;
      height: auto;
      width: auto;
      overflow: auto;
    }
    
    th {
      background-color:#ccc;
      border: 1px #ccc ;
      font-weight: normal;
      text-align: center;
      color: black;
      
      &:first-of-type {
        text-align: left; 
      }
    }
  }
  
  // Set these items to display: block for narrow viewports
  tbody,
  tr,
  th,
  td {
    display: block;
    padding: 0;
    text-align: left;
    white-space: normal;
  }
  
  tr {   
    @media (min-width: $bp-bart) {
      // Undo display: block 
      display: table-row; 
    }
  }
  
  th,
  td {
    padding: .5em;
    vertical-align: middle;
    
    @media (min-width: $bp-lisa) {
      padding: .75em .5em; 
    }
    
    @media (min-width: $bp-bart) {
      // Undo display: block 
      display: table-cell;
      padding: .5em;
    }
    
    @media (min-width: $bp-marge) {
      padding: .75em .5em; 
    }
    
    @media (min-width: $bp-homer) {
      padding: .75em; 
    }
  }
  
  caption {
    margin-bottom: 1em;
    font-size: 1em;
    font-weight: bold;
    text-align: center;
    
    @media (min-width: $bp-bart) {
      font-size: 1.5em;
    }
  }
  
  tfoot {
    font-size: .8em;
    font-style: italic;
    
    @media (min-width: $bp-marge) {
      font-size: .9em;
    }
  }
  
  tbody {
    @media (min-width: $bp-bart) {
      // Undo display: block 
      display: table-row-group; 
    }
    
    tr {
      margin-bottom: 1em;
      
      @media (min-width: $bp-bart) {
        // Undo display: block 
        display: table-row;
        border-width: 1px;
      }
      
      &:last-of-type {
        margin-bottom: 0; 
      }
      
      &:nth-of-type(even) {
        @media (min-width: $bp-bart) {
          background-color: rgba(0,0,0,.12);
        }
      }
    }
    
    th[scope="row"] {
      background-color: #ccc;
      color: white;
      
      @media (min-width: $bp-lisa) {
        border-left: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
      }
      
      @media (min-width: $bp-bart) {
        background-color: transparent;
        color: rgba(0,0,0.87);
        text-align: left;
      }
    }
    
    td {
      text-align: right;
      
      @media (min-width: $bp-bart) {
        border-left: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        text-align: center; 
      }
      
      &:last-of-type {
        @media (min-width: $bp-bart) {
          border-right: 1px solid #ccc;
        } 
      }
    }
    
    td[data-type=currency] {
      // text-align: right; 
    }
    
    td[data-title]:before {
      content: attr(data-title);
      float: left;
      font-size: .8em;
      color: rgba(0,0,0,.54);
      
      @media (min-width: $bp-lisa) {
        font-size: .9em; 
      }
      
      @media (min-width: $bp-bart) {
        // Don’t show data-title labels 
        content: none; 
      }
    } 
  }
}


// vlog-card

.blog-card-1{
  display: flex;
  padding: 100px 0px ;
}


@mixin mediaBig {
  @media (min-width: 1100px) {
    @content;
  }
}

@include mediaBig {
  :root {
    --font-size-title: 40px;
  }  
}
.video-i-1>video{
  border-radius: 15px;
}
.blog-card-v{
  border-radius: 20px !important;
  max-width: 800px;
  border-radius: var(--border-radius-primary);
  box-shadow: 24px 24px 80px rgba(0,0,0,0.1);
  padding: 20px 20px 28px 20px;
  box-sizing: border-box;
  margin: 20px;
  display: flex;
  flex-direction: column;
  
  @include mediaBig {
    flex-direction: row;
    align-items: center;
    margin: 40px;
    padding: 32px;
  }
}

.card__image {
  width: 100%;
  max-height: 300px;
  border-radius: var(--border-radius-primary);
  object-fit: cover;
  margin-bottom: 18px;
  
  @include mediaBig {
    width: 45%;
    max-height: none;
    min-height: 400px;
    margin-bottom: 0;
  }
}

.card__content {
  @include mediaBig {
    width: 55%;
    padding-left: 40px;
  }
}

.card__date {
  display: block;
  font-family: var(--font-family-secondary);
  font-size: var(--font-size-caption);
  line-height: var(--line-height-caption);
  text-transform: uppercase;
  color: var(--color-text);
  margin-bottom: 6px;
  
  @include mediaBig {
    margin-bottom: 8px;
  }
}

.card__title {
  font-family: var(--font-family-primary);
  font-size: var(--font-size-title);
  line-height: var(--line-height-title);
  color: var(--color-text);
  box-decoration-break: clone;
  background-image: linear-gradient(90deg, var(--color-highlight-primary), var(--color-highlight-secondary));
  background-size: 100% 42%;
  background-repeat: no-repeat;
  background-position: 0 85%;
  padding: 0 4px;
  margin-left: -4px;
  
  font-size: 24px;

}
@media only screen and (max-width: 1100px){
.blog-a3 {
    width: 100% !important;
}
}
.blog-a3 {
  width: 60%;
}





// light box



.lightbox {
	/* Hidden lightbox image */
	display: none;
	position: fixed;
	z-index: 999;
	width: 100%;
	height: 100%;
	text-align: center;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.8);

	img {
    width: 600px;
		margin-top: 10%;
    max-width: 90%;
	}

	&:target {
		/* Show the lightbox */
		display: block;
	}
}

.close {
	position: absolute;
	top: 20px;
	right: 20px;
	font-size: 3em;
	color: #fff;
	text-decoration: none;
}

.thumbnail {
	max-width: 180px;
}


@media only screen and (max-width: 600px){


  .lightbox img {
    max-width: 90%;
    max-height: 80%;
    margin-top: 35%;
}
  }
  @media only screen and (max-width: 400px){


    .lightbox img {
      max-width: 90%;
      max-height: 80%;
      margin-top: 40%;
  }
    }


