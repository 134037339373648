.bg-user {
  background-image: url("../../../assests/Img/20210204_a547.jpg");
  background-position: center;
  background-size: cover;
  height: 100vh;
}

.us-h1 {
  color: #0072BB;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
}
.head-use {
  padding: 20px 0px;
}

.cl1 {
  gap: 10px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}
.payment-status {
  display: flex;
  justify-content: space-around;
}

@media only screen and (max-width: 768px) {
  .payment-status {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
}

.view-btn-2 {
  padding: 8px 25px;
  color: white;
  background:#0072BB;
  border-radius: 8px;
  border: none;
}

@media only screen and (max-width: 370px) {
  .pay-status {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: center !important;
    gap: 1rem;
  }
}
.cl1 > span {
  font-size: 15px;
}

.status-btn-1 {
  padding: 8px 25px;
  color: white;
  background:#0072BB;
  border-radius: 8px;
  border: none;
}

.secb1 {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0px;
}

.secb2 {
  width: 90%;
}

.com-head > h1 {
  font-size: 25px;
  text-align: center;
  font-weight: 100;
}

.module-a15 {
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  padding: 28px;
  background: #a9a9a94a;
  align-items: center;
}
.modulea-22 {
  font-size: 15px;
  font-weight: 400;
  text-transform: uppercase;
  /* text-decoration-line: line-through; */
}
.svg-tick1 > svg {
  color:#0072BB;
}

.user-profile-n {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.searchbar {
  width: 100%;
  display: flex;
  justify-content: center;
}
