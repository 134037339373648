@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
*{
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}
.bDZlYX {
  position: unset !important;
  display: none !important;
  
  }
  .dXVOPX {
      min-width: unset !important; 
      max-width: unset !important; 
  
  }
  .downloadLink{
      display: none !important;
  }
  
  
  *{
    user-select: none !important;
  }
  
  @media only screen and (max-width: 600px) {
   
      .body-fix-h{
          overflow: unset !important;
          height:  unset !important;
        }
        .main-y{
          overflow-y: unset !;
          width:  unset !important;
        }
      
       
       
        .wrapper {
          display:  unset !important;
          height:  unset !important;
        }
  
  }
  .body-fix-h{
    overflow: hidden;
    height: 100vh;
  }
  .main-y{
    overflow-y: auto;
    width: 100%;
  }
  
  
  
  .wrapper {
    display: flex;
    height: 100%;
  }
  

 
  button {
 
    background-color: transparent;
    border: 0;
    outline: 0 !important;
    cursor: pointer;
}

textarea:focus, input:focus{
  outline: none;
}
.form-ddd{
  color: #0072BB;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
}