.c1a{
    width: 100%;
    padding: 20px 0px;
    display: flex;
    justify-content: center;
}
.c2a{
    width: 70%;
 
}
.a1-h1>h1{
padding: 5px 0px;
    font-size: 18px;
    font-weight: 500;
    color: #0072BB;
}
.bb-svg{
    color: #0072BB;

}
.a1-h2>h1{

        font-size: 18px;
        font-weight: 500;
    }
    .cad-ul{
        list-style-type: disc !important;
        padding: 10px 30px;
    }
    .cad-ul>li{
        color:rgb(72, 72, 72);
        line-height: 2;
        font-size: 15px;
        text-transform: uppercase;

    }
    .mod-card-bb{
        border: 1px solid;
    width: 400px;
    padding: 30px;
    background-color: white;
border-radius: 10px;
box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
    .card-container-h{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 1rem;

    }


    @media only screen and (max-width: 1445px){

    .c2a {
        width: 90%;
    }

}


@media only screen and (max-width: 1445px){

    .cad-ul>li {
        color: rgb(72, 72, 72);
        line-height: 2;
        font-size: 12px;
        text-transform: uppercase;
    }
    .a1-h1>h1 {
        padding: 5px 0px;
        font-size: 14px;
        font-weight: 500;
        color: #0072BB;
    }
    .a1-h2>h1 {
        font-size: 14px;
        font-weight: 500;
    }
    .mod-card-bb {
      
        width: 362px;
        padding: 12px;

     
    }
    
}
.dash-4{
 
    /* width: fit-content; */
    padding: 40px;
    display: flex;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    justify-content: space-between;
}

.main-dash {
    display: flex;
    justify-content: center;
    padding: 10px 0px;
}
.dash-1{
    width: 95%;
    
}


.dash-head-h1{

    padding: 20px 0px;
    color: #0072BB;
    text-align: center;
    font-weight: 400;
    font-size: 24px;
    text-transform: uppercase;
}
.user-detail-span1{
    font-size: 15px;
}
.user-detail-span2{
    font-size: 15px;
}
.dash-5{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 20px;
    border-radius: 5px;
    width: 250px;
}
.dash-6{
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    padding:10px 0px;
} 
.dash1-7{


}
.dash1-8{
    padding: 10px 0px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}