.bg-blog{

  
    background-image: url("../../../assests/Img/20210204_a547.jpg");
    background-position: center;
    background-size: cover;
    height: 100vh;
    
  }


.live-head-2{
display: flex;
align-items: center;
justify-content: center;
}
.blog-head-h>h1{
  color: #0072BB;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;

}
.vlog-c1{
padding:20px 0px;
width: 100%;
display: flex;
justify-content: center;
}
.live-head-3{
  width: 90%;
}
.view-btn1 {
  padding: 8px 25px;
  color: white;
  background:#0072BB;
  border-radius: 4px;
  border: none;
}
@media only screen and (max-width:600px) {


    .bg-blog{

  
        
        /* height: 150vh; */
        
      }

}
.vlog-btn1{
  padding: 8px 25px;
  color: white;
  background:#0072BB;
  border-radius: 8px;
  border: none;
}
.vlog-btn1-d{
  display: flex;
  justify-content: flex-end;
}

.vlog-edit1{

  padding: 8px 25px;
  color: white;
  background:#0072BB;
  border-radius: 8px;
  border: none;
}
.vlog-delete1{
  padding: 8px 25px;
  color: white;
  background:#0072BB;
  border-radius: 8px;
  border: none;
}
.del-vv1 {
  display: flex;
  
  padding: 20px 0px;

  gap: 1rem;
}


.v-file{
  padding: 10px 0px;
}